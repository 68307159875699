.card.user-info {
    box-shadow: none;
}

#profiletable .ui.basic.table thead>tr {
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
}

#profiletable .ui.header.turnoverLimHeader {
    font-size: 22px;
    font-weight: 600;
    line-height: 30px;
}

#profiletable .yellowLabel {
    margin-right: 1rem;
}

#profiletable .ui.styled.accordion .title.turnoverAccordionTitle {
    display: flex;
    align-items: center;
}

i.icon.chevronDownTurnovers {
    float: right;
}

@media only screen and (max-width: 767px) {
    .ui.header.myAccountHeader {
        padding: 0 0 10px 19px;
        font-size: 22px;
        font-weight: 600;
        line-height: 30px;
    }
    .ui.basic.table.eBankingTable {
        display: inline;
    }
}
