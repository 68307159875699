#submitTicket .descriptiontextarea textarea {
    width: 100%;
	border-radius: 5px;
}

#submitTicket .descriptiontextarea textarea:focus {
	outline: none;
    border-color: #85B7D9;
}

#submitTicket .ui.padded.segment {
	width: 50%;
}

#submitTicket label {
	font-weight: 600;
}

#submitTicket .uploadedFile {
	padding: 0px 1rem 1rem 1rem;
	font-size: 16px;
}

#submitTicket .deleteAttachment:hover {
	cursor: pointer;
	color: red;
}

#submitTicket .deleteAttachment {
	margin-left: auto;
}



@media only screen and (max-width: 768px) {
	#submitTicket .ui.padded.segment {
		width: 100%;
	}
}