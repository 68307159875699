.ui.segment.faqsegment {
    border-radius: 0px;
}

i.large.icon.anglerighticon {
    margin-top: 1em;
}

.ui.fluid.container.faqcontainer {
    margin-top: -3em;
}

.faqitem {
    display: flex;
    justify-content: center;
    align-items: center;
}

#faqitem {
    cursor: pointer;
}

.ui.header.faqbtnheader {
    font-size: 24px;
    font-weight: 600;
}

.ui.primary.button.faqbutton {
    height: 44px;
    font-size: 16px;
}

.ui.padded.segment {
    width: 100%;
}

.ui.basic.backButton {
    font-size: 16px;
    cursor: pointer;
}

.ui.styled.accordion.faqAccordian {
    border-radius: 0px !important;
    margin-bottom: 10px;
}

.ui.searchInput>button, .ui.searchInput>button:hover, .ui.searchInput>button:focus {
    background: #FFFFFF;
    border: solid rgba(34, 36, 38, 0.15);
    border-width: 1px 1px 1px 0px;
}

#Faq .ui.button:not(.icon.downloadbtnIcon)>.icon:not(.button):not(.dropdown) {
    color: #AAB7C4 !important;
}

#Faq .ui.basic.backButton {
    padding-top: 0;
}

#Faq .backIcon {
    margin: 0;
}

@media only screen and (max-width: 768px) {
    .ui.padded.segment.faqsegment {
        padding-left: 5px;
        padding-right: 5px;
        padding-bottom: 0px;
        padding-top: 24px;
    }
    .ui.padded.segment.faqitemssegment {
        padding-left: 5px;
        padding-right: 5px;
    }
    .ui.header.faqitemsheader {
        padding-left: 15px;
    }
    .ui.items:not(.unstackable)>.item.faqitem {
        flex-direction: row;
    }
    .ui.items:not(.unstackable)>.item>.image+.content.faqitemcontent {
        padding-top: 0px;
    }
    .ui.fluid.input.searchInput {
        margin-bottom: 32px;
    }
}