#login {
    min-height: 100%;
}

#login .column {
    max-width: 540px;
}

.ui.header .login-content {
    font-size: 1rem;
    font-weight: 400;
}

.ui.header .login-content.subtitle {
    font-weight: 600;
}

@media only screen and (max-width: 767px) {
    #login > .column > .ui.segment {
        border-radius: 0;
        padding: 0;
    }
}
