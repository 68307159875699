#webhookDocs .inputField {
	width: unset !important;
}

#webhookDocs .inputField input[disabled] {
	opacity: 1 !important;
}

#webhookDocs .inputField.ui.disabled.input, .ui.input:not(.disabled) input[disabled] {
	opacity: 1 !important;
}

#webhookDocs .editBTNS .ui.form .disabled.fields .field, .ui.form .disabled.field, .ui.form .field :disabled {
	opacity: 1 !important;
}