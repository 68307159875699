#root {
    min-height: 100%;
    display: flex;
}

.app-layout.ui.container {
    min-height: 100vh;
    display: grid;
    grid-template-rows: auto 1fr auto;
}

@media only screen and (max-width: 767px) {
    #root .ui.secondary.pointing.menu .item {
        padding: 12px 0;
        font-size: larger;
        margin-right: 16px;
    }
}
