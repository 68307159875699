#registration {
  width: 100%;
    position: relative;
    padding-bottom: 124px;
}

#registration-column {
  max-width: 540px;
}

#registration .ui.segment {
  border: none;
}

.ui.grid.phone, .ui.grid.name {
  padding: 1em 0;
}

@media only screen and (max-width: 767px) {
  #registration > .ui.grid > .column > .ui.segment {
      border-radius: 0;
      padding: 0;
  }

  #registration > .ui.grid > .column {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  #registration > .ui.grid > .column > .ui.segment {
    padding-left: 0.5em !important;
    padding-right: 0.5em !important;
  }

  #registration .ui.bottom.attached.segment.active.tab {
    padding-left: 0;
    padding-right: 0;
  }

  #registration .ui.bottom.attached.segment.active.tab > .ui.container {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
