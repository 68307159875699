/* Pagination */
#pagination.ui.pagination.menu {
    border: none;
    box-shadow: none;
}

#pagination.ui.pagination.menu .active.item {
    border-top: none;
    border-radius: 8px;
    color: white;
    box-shadow: none;
}

#pagination.ui.pagination.menu a.item:not(.active):hover {
    background-color: rgba(0, 0, 0, 0);
}

#pagination.ui.pagination.menu a.item:before {
    width: 0px;
}

#pagination.ui.pagination.menu a.item:focus {
    outline: none;
}
